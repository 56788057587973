/* Import of Stellantis fonts */

@font-face {
  font-family: "EncodeSansExpanded-Black";
  src: local("EncodeSansExpanded-Black"),
  url("./Fonts/EncodeSansExpanded-Black.ttf") format("truetype");
}

@font-face {
  font-family: "EncodeSansExpanded-Bold";
  src: local("EncodeSansExpanded-Bold"),
  url("./Fonts/EncodeSansExpanded-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "EncodeSansExpanded-ExtraBold";
  src: local("EncodeSansExpanded-ExtraBold"),
  url("./Fonts/EncodeSansExpanded-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "EncodeSansExpanded-ExtraLight";
  src: local("EncodeSansExpanded-ExtraLight"),
  url("./Fonts/EncodeSansExpanded-ExtraLight.ttf") format("truetype");
}


@font-face {
  font-family: "EncodeSansExpanded-Light";
  src: local("EncodeSansExpanded-Light"),
  url("./Fonts/EncodeSansExpanded-Light.ttf") format("truetype");
}

@font-face {
  font-family: "EncodeSansExpanded-Medium";
  src: local("EncodeSansExpanded-Medium"),
  url("./Fonts/EncodeSansExpanded-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "EncodeSansExpanded-Regular";
  src: local("EncodeSansExpanded-Regular"),
  url("./Fonts/EncodeSansExpanded-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "EncodeSansExpanded-SemiBold";
  src: local("EncodeSansExpanded-SemiBold"),
  url("./Fonts/EncodeSansExpanded-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "EncodeSansExpanded-Thin";
  src: local("EncodeSansExpanded-Thin"),
  url("./Fonts/EncodeSansExpanded-Thin.ttf") format("truetype");
}