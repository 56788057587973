#mainDivHomeComponent {
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  font-family: "Anton", sans-serif;
  justify-content: center;
  align-items: center;
}

.night {
  position: relative;
  width: 100%;
  height: 100%;
  animation: sky 200000ms linear infinite;
}

.shooting_star {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 2px;
  background: linear-gradient(-45deg, rgb(95, 145, 255), rgba(0, 0, 255, 0));
  border-radius: 999px;
  filter: drop-shadow(0 0 6px rgb(105, 155, 255));
  animation: tail 6000ms ease-in-out infinite, shooting 6000ms ease-in-out infinite;
}
.shooting_star::before {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  right: 0;
  height: 2px;
  background: linear-gradient(-45deg, rgba(0, 0, 255, 0), rgb(95, 145, 255), rgba(0, 0, 255, 0));
  transform: translateX(50%) rotateZ(45deg);
  border-radius: 100%;
  animation: shining 6000ms ease-in-out infinite;
}
.shooting_star::after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  right: 0;
  height: 2px;
  background: linear-gradient(-45deg, rgba(0, 0, 255, 0), rgb(95, 145, 255), rgba(0, 0, 255, 0));
  border-radius: 100%;
  animation: shining 6000ms ease-in-out infinite;
  transform: translateX(50%) rotateZ(-45deg);
}
.shooting_star:nth-child(1) {
  top: 5%;
  left: 58%;
  animation-delay: 83ms;
  opacity: 0.87;
}
.shooting_star:nth-child(1)::before, .shooting_star:nth-child(1)::after {
  animation-delay: 83ms;
}
.shooting_star:nth-child(2) {
  top: 93%;
  left: 5%;
  animation-delay: 1078ms;
  opacity: 0.86;
}
.shooting_star:nth-child(2)::before, .shooting_star:nth-child(2)::after {
  animation-delay: 1078ms;
}
.shooting_star:nth-child(3) {
  top: 90%;
  left: 19%;
  animation-delay: 2719ms;
  opacity: 0.61;
}
.shooting_star:nth-child(3)::before, .shooting_star:nth-child(3)::after {
  animation-delay: 2719ms;
}
.shooting_star:nth-child(4) {
  top: 4%;
  left: 97%;
  animation-delay: 4470ms;
  opacity: 0.76;
}
.shooting_star:nth-child(4)::before, .shooting_star:nth-child(4)::after {
  animation-delay: 4470ms;
}
.shooting_star:nth-child(5) {
  top: 91%;
  left: 37%;
  animation-delay: 9757ms;
  opacity: 0.76;
}
.shooting_star:nth-child(5)::before, .shooting_star:nth-child(5)::after {
  animation-delay: 9757ms;
}
.shooting_star:nth-child(6) {
  top: 48%;
  left: 21%;
  animation-delay: 2460ms;
  opacity: 0.65;
}
.shooting_star:nth-child(6)::before, .shooting_star:nth-child(6)::after {
  animation-delay: 2460ms;
}
.shooting_star:nth-child(7) {
  top: 60%;
  left: 3%;
  animation-delay: 2684ms;
  opacity: 0.71;
}
.shooting_star:nth-child(7)::before, .shooting_star:nth-child(7)::after {
  animation-delay: 2684ms;
}
.shooting_star:nth-child(8) {
  top: 96%;
  left: 24%;
  animation-delay: 2214ms;
  opacity: 0.95;
}
.shooting_star:nth-child(8)::before, .shooting_star:nth-child(8)::after {
  animation-delay: 2214ms;
}
.shooting_star:nth-child(9) {
  top: 87%;
  left: 75%;
  animation-delay: 8396ms;
  opacity: 0.73;
}
.shooting_star:nth-child(9)::before, .shooting_star:nth-child(9)::after {
  animation-delay: 8396ms;
}
.shooting_star:nth-child(10) {
  top: 79%;
  left: 98%;
  animation-delay: 4735ms;
  opacity: 0.95;
}
.shooting_star:nth-child(10)::before, .shooting_star:nth-child(10)::after {
  animation-delay: 4735ms;
}
.shooting_star:nth-child(11) {
  top: 22%;
  left: 82%;
  animation-delay: 8927ms;
  opacity: 0.61;
}
.shooting_star:nth-child(11)::before, .shooting_star:nth-child(11)::after {
  animation-delay: 8927ms;
}
.shooting_star:nth-child(12) {
  top: 92%;
  left: 95%;
  animation-delay: 5026ms;
  opacity: 0.88;
}
.shooting_star:nth-child(12)::before, .shooting_star:nth-child(12)::after {
  animation-delay: 5026ms;
}
.shooting_star:nth-child(13) {
  top: 85%;
  left: 24%;
  animation-delay: 2973ms;
  opacity: 0.89;
}
.shooting_star:nth-child(13)::before, .shooting_star:nth-child(13)::after {
  animation-delay: 2973ms;
}
.shooting_star:nth-child(14) {
  top: 99%;
  left: 1%;
  animation-delay: 7706ms;
  opacity: 0.63;
}
.shooting_star:nth-child(14)::before, .shooting_star:nth-child(14)::after {
  animation-delay: 7706ms;
}
.shooting_star:nth-child(15) {
  top: 55%;
  left: 19%;
  animation-delay: 470ms;
  opacity: 0.69;
}
.shooting_star:nth-child(15)::before, .shooting_star:nth-child(15)::after {
  animation-delay: 470ms;
}
.shooting_star:nth-child(16) {
  top: 26%;
  left: 13%;
  animation-delay: 5798ms;
  opacity: 0.95;
}
.shooting_star:nth-child(16)::before, .shooting_star:nth-child(16)::after {
  animation-delay: 5798ms;
}
.shooting_star:nth-child(17) {
  top: 80%;
  left: 50%;
  animation-delay: 1489ms;
  opacity: 0.66;
}
.shooting_star:nth-child(17)::before, .shooting_star:nth-child(17)::after {
  animation-delay: 1489ms;
}
.shooting_star:nth-child(18) {
  top: 85%;
  left: 58%;
  animation-delay: 4780ms;
  opacity: 0.52;
}
.shooting_star:nth-child(18)::before, .shooting_star:nth-child(18)::after {
  animation-delay: 4780ms;
}
.shooting_star:nth-child(19) {
  top: 46%;
  left: 35%;
  animation-delay: 7734ms;
  opacity: 0.75;
}
.shooting_star:nth-child(19)::before, .shooting_star:nth-child(19)::after {
  animation-delay: 7734ms;
}
.shooting_star:nth-child(20) {
  top: 79%;
  left: 80%;
  animation-delay: 1935ms;
  opacity: 0.62;
}
.shooting_star:nth-child(20)::before, .shooting_star:nth-child(20)::after {
  animation-delay: 1935ms;
}

@keyframes tail {
  0% {
    width: 0;
  }
  30% {
    width: 100px;
  }
  100% {
    width: 0;
  }
}
@keyframes shining {
  0% {
    width: 0;
  }
  50% {
    width: 30px;
  }
  100% {
    width: 0;
  }
}
@keyframes shooting {
  0% {
    transform: translateX(-1000px);
  }
  100% {
    transform: translateX(1000px);
  }
}
@keyframes sky {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}


